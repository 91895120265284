import useCustomerInfo from './useCustomerInfo'
import useCompanyUserInfo from './useCompanyUserInfo'
import { HookUtils } from '../../../utils'

const useUserInfo = (user_id, service_id) => {
  const [customer_info, customer_info_state] = useCustomerInfo(user_id)
  const [companyuser_info, companyuser_info_state] = useCompanyUserInfo(user_id, service_id)
  return [
    !service_id ? customer_info : companyuser_info,
    {
      isReady: HookUtils.getReadyState([customer_info_state, companyuser_info_state]),
      isLoading: HookUtils.getLoadingState([customer_info_state, companyuser_info_state])
    }
  ]
}

export default useUserInfo
