import { useEffect } from "react"
import { ProviderInstance } from "../../../provider"

function useSocketEvent(event_name, callback) {
  useEffect(() => {
    return ProviderInstance.getProvider('socket').listen(event_name, callback)
  }, [event_name, callback])
}

export default useSocketEvent
