import { useCallback, useSyncExternalStore } from 'react'
import useProviderStatusListener from "../root/useProviderStatusListener"
import { ProviderInstance } from "../../provider"
const useRelayServer = () => {
  return useSyncExternalStore(
    useProviderStatusListener('relay'),
    useCallback(() => ProviderInstance.getProvider('relay').getDefaultServer(), [])
  )
}

export default useRelayServer
