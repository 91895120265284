import { useEffect, useCallback, useMemo } from 'react'
import shortid from 'shortid'
import { ProviderInstance } from '../../../provider'
import useModelListener from '../useModelListener'

const useModelSubscription = (model_name, info, options, watchers) => {
  const {
    search_id,
    listener_id: inputListenerId = search_id,
    listener_type,
  } = options || {}
  const listener_id = useMemo(() => inputListenerId || shortid.generate(), [inputListenerId])
  const listener = useCallback(ProviderInstance.getProvider('store').getModel(model_name).prepareSubscription(listener_id, info, options), watchers || [])
  const getListener = useModelListener(model_name, listener_id, listener_type)
  useEffect(() => () => ProviderInstance.getProvider('store').getModel(model_name).destroyListener(listener_id), [listener_id])

  return [
    listener,
    getListener,
    listener_id
  ]
}

export default useModelSubscription
