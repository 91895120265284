import { useMemo, useCallback } from 'react'
import { useGlobalForm, useGlobalFormState } from '../../../hooks'

import _ from 'lodash'
const useGlobalSection = (form_name, path) => {
  const full_path = _.flatten([path]).join('.')
  const parent_path = _.flatten([_.dropRight(path)]).join('.')
  const local_index = _.head(_.takeRight(path, 1))
  const [values, bulkChange, , { bulkRemove, addEnabledSections, removeEnabledSections }] = useGlobalForm(form_name)
  const [, setFormReadOnly] = useGlobalFormState(form_name, 'isReadOnly')
  const value = useMemo(() => _.get(values, full_path), [values, full_path])
  const sectionBulkChange = useCallback((data) => bulkChange(_.map(data, (d) => _.merge({}, d, { field: _.flatten([full_path, d.field]).join('.') }))), [bulkChange, full_path])
  const removeSection = useCallback(() => bulkRemove([{ section: parent_path, index: local_index }]), [bulkRemove, parent_path, local_index])
  const setReadOnly = useCallback(enabled => {
    if (!full_path)
      return setFormReadOnly(enabled);
    return !enabled ? addEnabledSections(_.compact([full_path])) : removeEnabledSections(_.compact([full_path]));
  }, [full_path, addEnabledSections, removeEnabledSections, setFormReadOnly])
  return [value, sectionBulkChange, { removeSection, setReadOnly }]
}

export default useGlobalSection
