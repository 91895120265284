import {
  GlobalFormContext,
} from '../..'
import { useContext } from 'react'

const useGlobalFormValues = (form_name) => {
  const { getFormValues } = useContext(GlobalFormContext)
  return getFormValues(form_name)
}

export default useGlobalFormValues
