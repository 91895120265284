import Storage from "./providers/storage"
import Relay from "./providers/relay"
import Auth from "./providers/auth"
import Fetch from './providers/fetch'
import Store from "./providers/store"
import Socket from './providers/socket'
import Global from "./providers/global"
import Form from './providers/form'
import SetUtils from '../utils/set'
class Root {
  constructor() {
    this.providers = new Set([
      new Storage('storage', this),
      new Global('global', this),
      new Relay('relay', this),
      new Auth('auth', this),
      new Fetch('fetch', this),
      new Socket('socket', this),
      new Store('store', this),
      new Form('form', this)
    ])
  }
  getProviders() {
    return this.providers
  }
  getProvider(name) {
    return SetUtils.find(this.getProviders(), provider => provider.getProviderId() === name);
  }
  addProvider(provider) {
    const existing_provider = this.getProvider(provider.getProviderId())
    if (!!existing_provider)
      console.warn(`Provider ${provider.getProviderId()} already exists`)
    else {
      this.getProviders().add(provider.setRoot(this))
      provider.init()
    }
  }
  removeProvider(name) {
    const removed_instance = SetUtils.remove(this.getProviders(), (provider) => provider.getProviderId() === name)
    return removed_instance?.deinit()
  }
  prepareDynamicProvider(instance) {
    return function getSubscription(listener) {
      this.addProvider(instance)
      const destroyer = instance.prepareStatusSubscription()(listener)
      return () => {
        destroyer()
        this.removeProvider(instance?.getProviderId())
      }
    }.bind(this)
  }
  async init(config) {
    const { relay, model, storage, global } = config
    await this.getProvider('storage').init(storage)
    await this.getProvider('global').init(global)
    await this.getProvider('store').init(model)
    await this.getProvider('relay').init(relay)
    await this.getProvider('auth').init()
    await this.getProvider('fetch').init()
    await this.getProvider('form').init()
  }
  async deinit() {
    return this.getProviders().forEach((provider) => provider?.deinit())
  }
  getProviderStatus(name) {
    return this.getProvider(name)?.getProviderStatus() || false
  }
}


export default Root
