import _ from 'lodash'
import { useCallback } from 'react'
import isEqual from 'react-fast-compare'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { ProviderInstance } from '../../../provider'
import useModelSubscription from '../useModelSubscription'

const useModelValues = (model_name, method, initial_params, options) => {
  const [listener, getListener, listener_id] = useModelSubscription(model_name, initial_params, _.merge({}, options, { listener_type: 'method', method_name: method }), _.get(options, 'watchers') || [])
  const func = useCallback(ProviderInstance.getProvider('store').getModelMethod(model_name, method, listener_id), [model_name, method, listener_id])

  const [result, status] = useSyncExternalStoreWithSelector(
    listener,
    useCallback(() => [
      ProviderInstance.getProvider('store').getModel(model_name).getListenerResult(listener_id),
      ProviderInstance.getProvider('store').getModel(model_name).getFullListenerStatus(listener_id)
    ], []),
    null,
    _.identity,
    isEqual
  )
  const clear = useCallback(() => getListener()?.clear(), [getListener])
  return [result, func, status, { clear, getListener }]
}
export default useModelValues
