import { useCallback } from 'react'
import Callbacks from './callbacks'
import useModelSubscription from '../useModelSubscription'

const useModelSearchSubscription = (model_name, method, options, watchers) => {
  const {
    search_id,
    listener_id: inputListenerId = search_id,
    watch_type,
    enableLogQuery,
    enableCount,
    ...searchOptions
  } = options || {}
  const {
    initial_filter,
    default_filter,
    validate_filter,
    forced_filter,
    default_limit,
    default_page,
    default_sort,
    incremental,
    mode,
    populate,
    sort,
  } = searchOptions || {}

  const [listener, getListener, listener_id] = useModelSubscription(model_name, {
    initial_filter,
    default_filter,
    validate_filter,
    forced_filter,
    default_limit,
    default_page,
    default_sort,
    incremental,
    mode,
    sort,
    populate
  }, { method_name: method, listener_id: inputListenerId, watch_type, listener_type: 'search', enableLogQuery, enableCount },
    watchers || []
  )
  const search = useCallback(Callbacks.searchHandler(getListener), [getListener])
  const filterSearch = useCallback((filter) => search(filter, { skip: 0 }, false, { action: 'reset' }), [search])
  const clear = useCallback(Callbacks.clearHandler(getListener), [getListener])
  const reset = useCallback(Callbacks.resetHandler(getListener), [getListener])
  const refresh = useCallback(Callbacks.refreshHandler(getListener), [getListener])
  const setPageSize = useCallback(Callbacks.setPageSizeHandler(search, getListener), [search, getListener])
  const changePage = useCallback(Callbacks.changePageHandler(search, getListener), [search, getListener])
  const changeSort = useCallback(Callbacks.ChangeSortHandler(search, getListener), [search, getListener])
  const goNext = useCallback(Callbacks.goNextHandler(changePage, getListener), [changePage, getListener])
  const goPrev = useCallback(Callbacks.goPrevHandler(changePage, getListener), [changePage, getListener])
  const goFirst = useCallback(Callbacks.goFirstHandler(changePage, getListener), [changePage, getListener])
  const goLast = useCallback(Callbacks.goLastHandler(changePage, getListener), [changePage, getListener])

  const controls = {
    search,
    filterSearch,
    clear,
    reset,
    refresh,
    setPageSize,
    changePage,
    changeSort,
    goNext,
    goPrev,
    goBack: goPrev,
    goFirst,
    goLast,
    getListener
  }

  return [
    listener,
    listener_id,
    controls
  ]
}

export default useModelSearchSubscription
