import React, { createContext } from 'react'
const LocalContext = createContext({})
const LocalContextProvider = LocalContext.Provider

const LocalProvider = (props) => {
  const { context, children } = props
  return (
    <LocalContextProvider value={context}>{children}</LocalContextProvider>
  )
}
export {
  LocalContext,
}
export default React.memo(LocalProvider)
