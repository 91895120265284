import { useCallback, useSyncExternalStore } from 'react'
import useProviderStatusListener from "../root/useProviderStatusListener"
import { ProviderInstance } from "../../provider"
const useIdentity = () => {
  return useSyncExternalStore(
    useProviderStatusListener('relay'),
    useCallback(() => ProviderInstance.getProvider('relay').getIdentity(), [])
  )
}

export default useIdentity
