import HookUtils from '../../../utils/hooks'
import useModel from '../../store/useModel'

const pickup_list = ['planned', 'started', 'at_src']
const dropoff_list = ['on_board', 'at_dst', 'off_board']
const active_list = _.flatten([pickup_list, dropoff_list])
const user_list = ['planned', 'started', 'at_src', 'on_board', 'off_board', 'complete']
const package_list = ['planned', 'started', 'at_src', 'on_board', 'at_dst', 'off_board', 'complete']

const getNextStatus = (status, status_list, hasReturnStep) => {
  const current_index = _.indexOf(status_list, status)
  if (status === 'off_board' && !!hasReturnStep)
    return 'on_board'
  if (current_index === -1)
    return status
  return current_index < status_list.length - 1 ? status_list[current_index + 1] : status;
}
const getNextSteps = (active_steps, job, status_list) => {
  const next_steps = _.filter(active_steps, ['index', _.get(job, 'current_index')])
  const pickup_steps = _.filter(next_steps, ['type', 'pickup'])
  const dropoff_steps = _.filter(next_steps, ['type', 'dropoff'])
  const min_pickup_step = _.minBy(pickup_steps, (st) => _.indexOf(status_list, _.get(st, 'order.assigned_transport.status')))
  const min_dropoff_step = _.minBy(dropoff_steps, (st) => _.indexOf(status_list, _.get(st, 'order.assigned_transport.status')))
  const dropoff_index = _.indexOf(status_list, _.get(min_dropoff_step, 'order.assigned_transport.status'))
  if (!_.isEmpty(dropoff_steps) && dropoff_index < _.indexOf(status_list, 'off_board'))
    return _.filter(dropoff_steps, ['order.assigned_transport.status', _.get(min_dropoff_step, 'order.assigned_transport.status')])
  return _.filter(pickup_steps, ['order.assigned_transport.status', _.get(min_pickup_step, 'order.assigned_transport.status')])
}
const useJobInfo = (input, options) => {
  const job_id = _.get(input, 'job_id')
  const [job, jobState] = useModel('job', [job_id], { populate: ['steps.order.assigned_transport', 'steps.order.commercial_package'], single: true })
  const active_steps = _.orderBy(_.filter(_.get(job, 'steps'), step => {
    return !_.includes(['canceled', 'complete'], _.get(step, 'order.assigned_transport.status')) &&
      (_.get(step, 'order.status') !== 'canceled' || !!_.get(step, 'order.assigned_transport'))
  }), ['index', 'type'], ['asc', 'asc'])
  const status_list = _.get(_.head(active_steps), 'order.commercial_package.load_type') === 'user' ? user_list : package_list
  const next_steps = getNextSteps(active_steps, job, status_list)
  const hasReturnStep = !_.isEmpty(_.filter(active_steps, step => _.includes(_.map(next_steps, 'order.order_id'), step?.order?.order_id) && !_.includes(_.map(next_steps, 'step_id'), step?.step_id) && step?.direction === 'return'))

  const [steps, stepState] = useModel('step', _.map(next_steps, 'step_id'), options)
  const current_status = _.minBy(_.map(next_steps, 'order.assigned_transport.status'), (status) => _.indexOf(active_list, status))
  const target_status = getNextStatus(current_status, status_list, hasReturnStep)
  const load_type = _.get(_.head(next_steps), 'order.commercial_package.load_type')
  const isB2B = !!_.get(_.head(next_steps), 'order.service')
  const ride_mode = _.get(_.head(next_steps), 'order.mode')
  const isActiveStatus = _.includes(active_list, current_status)
  const isPickupStatus = _.includes(pickup_list, current_status)
  const isDropoffStatus = _.includes(dropoff_list, current_status)
  const isLastDropOff = _.head(next_steps)?.step_id === _.last(active_steps)?.step_id && !!isDropoffStatus
  const isFinalStep = !target_status || target_status === 'complete' || target_status === 'canceled'
  const active_uniq_steps = _.uniqWith(active_steps, (st1, st2) => _.get(st1, 'index') === _.get(st2, 'index') && _.get(st1, 'type') === _.get(st2, 'type'))
  const current_index = _.findIndex(active_uniq_steps, (st) => _.get(st, 'index') === _.get(job, 'current_index') && _.get(st, 'type') === _.get(_.head(next_steps), 'type'))
  const total_steps = active_uniq_steps.length

  return [{
    job_id,
    next_steps: steps,
    next_transports: _.map(next_steps, 'order.assigned_transport'),
    current_status,
    target_status,
    load_type,
    isB2B,
    isGrouped: !!_.get(job, 'grouped'),
    current_index,
    total_steps,
    isActiveStatus,
    isPickupStatus,
    isDropoffStatus,
    isLastDropOff,
    isFinalStep,
    ride_mode
  }, { isLoading: HookUtils.getLoadingState([jobState, stepState]) }]

}


export default useJobInfo
