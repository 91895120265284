import { useEffect } from 'react'
import shortid from 'shortid'
import md5 from 'md5'
import useGlobalState from '../useGlobalState'
const useDeviceID = () => {
  const [deviceID, setDeviceID, , , { isReady }] = useGlobalState('persist.device_id')
  useEffect(() => {
    if (!deviceID && !!isReady)
      setDeviceID(md5(shortid.generate()))
  }, [isReady, deviceID])
  return deviceID
}


export default useDeviceID
