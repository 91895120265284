import { useContext } from 'react'
import { SectionContext } from '../../section'
import { useFormName } from '../../hooks'

const useSectionPath = () => {
  const { path, form_name } = useContext(SectionContext)
  const section_form_name = useFormName()

  if (form_name !== section_form_name) return []
  return path
}

export default useSectionPath
