import { useCallback } from 'react'
import _ from 'lodash'
import isEqual from 'react-fast-compare'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import useModelSearchSubscription from '../useModelSearchSubscription'
import useSearchListenerStatus from '../useSearchListenerStatus'
import { ProviderInstance } from '../../../provider'
const useModelSearchCount = (model_name, method, options) => {
  const { watchers, watch, ...config } = options || {}
  const combined_options = _.merge({ enableCount: true }, config, !!watch ? { watch_type: 'count' } : {})
  const [listener, listener_id, controls] = useModelSearchSubscription(model_name, method, combined_options, watchers)
  const count = useSyncExternalStoreWithSelector(
    listener,
    useCallback(() => ProviderInstance.getProvider('store').getModel(model_name).getListener(listener_id)?.getCount() || 0, []),
    null,
    _.identity,
    isEqual
  )
  const status = useSearchListenerStatus(model_name, listener_id)
  const userSearch = useCallback((filter) => controls.search(filter), [controls])
  return [count, userSearch, status]
}

export default useModelSearchCount
